import { postToPropServer } from './common';
/**
 * 发布更新需求(草稿)
 */
export async function add_tmpRequire(obj) {
    return await postToPropServer(`/requirement/my/add_tmp`, obj);
}
/**
 * 发布更新需求(直接发布)
 */
export async function add_pubRequire(obj) {
    return await postToPropServer(`/requirement/my/add_pub`, obj);
}
/**
 * 重新发布需求(直接发布)
 */
export async function copy_pubRequire(id) {
    return await postToPropServer(`/requirement/my/copy/${id}`);
}
/**
 * 我发布的需求列表
 */
export async function getRequireList(obj) {
    return await postToPropServer(`/requirement/my/list`, obj);
}
/**
 * 编辑需求
 */
export async function editRequire(obj) {
    return await postToPropServer(`/requirement/my/mod`, obj);
}
/**
 * 达成需求
 */
export async function dcRequire(id) {
    return await postToPropServer(`/requirement/my/dc/${id}`);
}
/**
 * 生成订单(买方调用)
 */
export async function dayangOrder(obj) {
    return await postToPropServer(`/requirement/my/order/dayang/new`, obj);
}
/**
 * 订单列表(买方)
 */
export async function dayangBuyerList(obj) {
    return await postToPropServer(`/requirement/my/order/dayang/buyer/list`, obj);
}
/**
 * 订单列表(卖方)
 */
export async function dayangSellerList(obj) {
    return await postToPropServer(`/requirement/my/order/dayang/seller/list`, obj);
}
/**
 * 改款(设计)订单-生成订单(买方调用)
 */
export async function gaikuangOrder(obj) {
    return await postToPropServer(`/requirement/my/order/gaikuang/new`, obj);
}
/**
 * 改款(设计)订单-订单列表(买方)
 */
export async function gaikuangBuyerList(obj) {
    return await postToPropServer(`/requirement/my/order/gaikuang/buyer/list`, obj);
}
/**
 * 订单列表(卖方)
 */
export async function gaikuangSellerList(obj) {
    return await postToPropServer(`/requirement/my/order/gaikuang/seller/list`, obj);
}
/**
 * 关闭需求
 */
export async function closeRequire(id) {
    return await postToPropServer(`/requirement/my/close/${id}`);
}
/**
 * 删除需求
 */
export async function delRequire(id) {
    return await postToPropServer(`/requirement/my/del/${id}`);
}
/**
 * 获取需求详情
 */
export async function getRequireId(id, obj) {
    return await postToPropServer(`/requirement/my/get/${id}`, obj);
}
/**
 * 获取配套需求的报价列表（销售公司）
 */
export async function requireOfferList(id, obj) {
    return await postToPropServer(`/requirement/my/offer/list/${id}`, obj);
}
/**
 * 获取配套需求的报价详情（销售公司）
 */
export async function requireOfferId(id) {
    return await postToPropServer(`/requirement/my/offer/get/${id}`);
}
/**
 * 需求回复列表
 */
export async function replyList(offer_id) {
    return await postToPropServer(`/requirement/my/reply/list/${offer_id}`);
}
/**
 * 回复需求
 */
export async function addReply(obj) {
    return await postToPropServer(`/requirement/my/reply/add`, obj);
}
/**
 * 修改回复
 */
export async function editReply(id, obj) {
    return await postToPropServer(`/requirement/my/reply/mod/${id}`, obj);
}
/**
 * 删除回复
 */
export async function delReply(id) {
    return await postToPropServer(`/requirement/my/reply/del/${id}`);
}
/**
 * 应答（报价）
 * type 需求类型
 *      peitao 配套需求
 *      gaikuang 改款设计
 *      dingzhi 找工厂定制
 *      dayang 打样
 */
export async function peitaoOfferAdd(obj) {
    return await postToPropServer(`/requirement/my/offer/add`, obj);
}
/**
 * 修改应答（报价）
 */
export async function peitaoOfferModify(obj) {
    return await postToPropServer(`/requirement/my/offer/modify`, obj);
}
/**
 * 设置合同信息
 */
export async function setContract(obj) {
    return await postToPropServer(`/requirement/my/offer/set_ht`, obj);
}
/**
 * 可选配套商品列表（工厂）
 */
export async function peitaoProducts(obj) {
    return await postToPropServer(`/requirement/my/peitao/products`, obj);
}
/**
 * 可选配套商品列表（设计公司）
 */
export async function gaikuangProducts(obj) {
    return await postToPropServer(`/requirement/my/gaikuang/products`, obj);
}
/**
 * 我能应答的需求列表（工厂）
 */
export async function getRequirePlistList(obj) {
    return await postToPropServer(`/requirement/my/plist`, obj);
}
/**
 * 打样订单详情
 */
export async function getDayangOrderDetail(id) {
    return await postToPropServer(`/requirement/my/order/dayang/get/${id}`);
}
/**
 * 改款设计订单详情
 */
export async function getGaikuangOrderDetail(id) {
    return await postToPropServer(`/requirement/my/order/gaikuang/get/${id}`);
}
/**
 * 设计作品列表
 */
export async function getCommitList(orderId) {
    return await postToPropServer(`/requirement/my/order/gaikuang/item/list/${orderId}`);
}
/**
 * 添加设计作品
 */
export async function addDesign(obj) {
    return await postToPropServer(`/requirement/my/order/gaikuang/item/add`, obj);
}
/**
 * 删除设计作品
 */
export async function delDesign(id) {
    return await postToPropServer(`/requirement/my/order/gaikuang/item/del/${id}`);
}

